import React, { useContext } from 'react';
import { AppContext } from '../contexts/context';

// Components
import ScanDialog from './ScanDialog'
import DataDisplay from '../components/DataDisplay'
import AppSnackbar from './AppSnackbar';
import {
  AttentionCard,
  AppInfoCard,
  AppUpdateCard,
  NfcNotAvailableCard,
  NfcNotGrantedCard,
  AppInstallableCard,
  AppOpenCard
} from './AppCards';

// Material UI
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
import { makeStyles } from '@mui/styles';
import ContactlessIcon from '@mui/icons-material/Contactless';

const useStyles = makeStyles((theme) => ({
  card: {
    margin: 10,
    paddingBottom: 0,
  },
  button: {
    marginRight: 10,
  },
  snackbar: {
    /* [theme.breakpoints.down('xs')]: {
      bottom: 90,
    }, */
  },
}));

function DataPage(props) {
  const classes = useStyles();
  const [ state, dispatch ] = useContext(AppContext);

  // console.debug("DataPage: Re-Render ...")

  const onChangeActionFab = () =>{
    //Set Scan Dialogue State values
    dispatch({type: 'app/ui/dialog/ndef/show', value: true})
    dispatch({type: 'ndef/scan/scanning'});
    dispatch({type: 'ndef/control/reading'});
  }

  const handleAbortClick = () => {
    console.debug('handleAbortClick')
    dispatch({type: 'app/ui/dialog/ndef/show', value: false})
    dispatch({type: 'ndef/control/idle'});
  }

  const handleComfirmationClick = () => {
    console.debug('handleComfirmationClick')
    dispatch({type: 'app/ui/dialog/ndef/show', value: false})
    dispatch({type: 'ndef/control/idle'});
  }

  const handlRetryClick = () => {
    console.debug('handlRetryClick')
    // dispatch({type: 'ndef/control/setForNewRead'});
    dispatch({type: 'app/ui/dialog/ndef/show', value: false})
    dispatch({type: 'app/ui/dialog/ndef/show', value: true})
    dispatch({type: 'ndef/control/reading'});
  }

  return (
    <>
      {Object.keys(state.nfcSchema).length <= 0
        ? <div style={{marginInline:10, marginBlock:10, display:"flex", gap:10, flexDirection:"column"}}>
            <AppInfoCard/>
            {state.uiCardNfcNotAvailable === 'show'
              ? <NfcNotAvailableCard/>
              : state.uiCardNfcNotGranted === 'show'
                ? <NfcNotGrantedCard/>
                : <AttentionCard Title={"Getting started"} Text={`Press the DATA EXCHANGE button to start the contactless reading of data.`} grantHandler={props.grantHandler}/>
            }
            {state.uiCardAppInstallable === 'show'
              ? <AppInstallableCard/>
                : state.uiCardAppOpen === 'show'
                  ? <AppOpenCard/>
                  : ''
            }
            {state.appSwUpdated === true
              ? <AppUpdateCard/>
              : <></>
            }
          </div>
        : ''
      }
      {(Object.keys(state.nfcSchema).length > 0 && state.ndefControl === 'idle') && (
          <DataDisplay />
      )}

      {state.uiScanDialog?
        <ScanDialog
          abortHandler={handleAbortClick}
          retryHandler={handlRetryClick}
          confirmHandler={handleComfirmationClick}
        /> :
        <div></div>
      }

      {state.nfcAvailable === true
        ? state.nfcPermission === 'granted'
          ? <Zoom
              in={true} style={{ transitionDelay: '500ms'}}
            >
              <Fab
                variant="extended"
                color="primary"
                aria-label="scan"
                onClick={onChangeActionFab}
                sx={{position:"fixed", minWidth:80, height:56, bottom:16, right:16}}>
                  <ContactlessIcon sx={{ mr: 1 }} />
                  Data Exchange
              </Fab>
            </Zoom>
          : <Zoom
              in={true} style={{ transitionDelay: '500ms'}}
            >
            <Fab
              disabled
              variant="extended"
              color="primary"
              aria-label="scan"
              sx={{position:"fixed", minWidth:80, height:56, bottom:16, right:16}}
              >
                <ContactlessIcon sx={{ mr: 1 }} />
                Data Exchange
            </Fab>
          </Zoom>
        : <Zoom
            in={true} style={{ transitionDelay: '500ms'}}
          >
            <Fab
              disabled
              variant="extended"
              color="primary"
              aria-label="scan"
              sx={{position:"fixed", minWidth:80, height:56, bottom:16, right:16}}
              >
                <ContactlessIcon sx={{ mr: 1 }} />
                Data Exchange
            </Fab>
          </Zoom>
      }
      <AppSnackbar
        classes={classes.snackbar}
      />
    </>
  )
};

export default DataPage;
