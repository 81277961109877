import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../contexts/context';

import { makeStyles } from '@mui/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// Tools
import QRCode from 'qrcode.react'

const useStyles = makeStyles(() => ({
  qrCode: {
    marginBottom: '20px'
  }
}));

const OpenOnOtherDeviceDialog = () => {
  const classes = useStyles();
  const [ state, dispatch ] = useContext(AppContext);
  const [ open, setOpen ] = useState(false);

  useEffect(() => {
    if (state.uiDialog === 'appLink') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [state.uiDialog]);

  const handleCloseClick = () => {
    dispatch({type: 'app/ui/dialog/default/close'});
  }

  return (
    <div>
      <Dialog onClose={handleCloseClick} aria-labelledby="form-dialog-appLink" open={open}>
        <DialogTitle id="form-dialog-appLink" onClose={handleCloseClick}>
            Open on other Device
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Scan this QR code on a device with Android & Chrome browser.
          </Typography>
          <Typography style={{ margin: '15px'}} gutterBottom> </Typography>
          <QRCode className={classes.qrCode} size={256} value={state.currentURL} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseClick} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
};

export default OpenOnOtherDeviceDialog;
