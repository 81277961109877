import React, { useContext, useState, forwardRef} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import AutoModeOutlinedIcon from '@mui/icons-material/AutoModeOutlined';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import HealingIcon from '@mui/icons-material/Healing';
import Checkbox from '@mui/material/Checkbox';
import NfcIcon from '@mui/icons-material/Nfc';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { AppContext } from '../contexts/context';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NfcCheckBox = () => {
  const [ state, dispatch ] = useContext(AppContext);
  const [nfcPermissionSwitch, setNfcPermissionSwitch] = useState(false);

  const handleNFCPermissionSwitch=()=>{
    setNfcPermissionSwitch(!nfcPermissionSwitch)
    dispatch({type: 'app/ui/control/nfcGrantCheck', value: true});
}

  switch (state.nfcPermission) {
    case "granted":
      return(<CheckBoxIcon sx={{justifySelf:"left", marginRight:1}}/>);
    case "denied":
      return(<IndeterminateCheckBoxIcon sx={{justifySelf:"left", marginRight:1}} />);
    case "prompt":
      return(<Checkbox checked={false} onChange={handleNFCPermissionSwitch} sx={{justifySelf:"left"}}/>);
    default:
  }
}

export default function AppSettingsDialog(props) {
  const [ state, dispatch ] = useContext(AppContext);

  const handleClose = () => {
    dispatch({type: 'app/ui/menu/dialog/default/close'});
  };

  const handleAppInstallSwitch=()=>{
    dispatch({type: 'app/ui/control/appInstallTrigger', value: true});
  }

  const seamlessWriteTogglehandler=()=>{
    dispatch({type: 'app/settings/seamlessWrite/toggle', value: !state.settingSeamlessWrite});
  }

  const forceWriteTogglehandler=()=>{
    dispatch({type: 'app/settings/forceWrite/toggle', value: !state.settingForceWrite});
  }

  const textSecondaryGrantNfc = {
    granted: `Permission to use NFC has been granted. Reset only possible via website settings.`,
    denied: "Use of NFC was blocked by the user. Reset only possible via website settings.",
    prompt: "To use the app, NFC must be allowed.",
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box sx={{ position: 'relative', backgroundColor:"#fff", mb:2 }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon sx={{color:'#3b3b3b'}}/>
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, color:"#3b3b3b" }} variant="h6" component="div">
              Settings
            </Typography>
            <Button autoFocus color="inherit" sx={{color:"#3b3b3b"}} onClick={handleClose}>
              save
            </Button>
          </Toolbar>
        </Box>
        <Box sx={{paddingInline:2}} >
        <List
          sx={{ width: '100%', maxWidth: 450, bgcolor: 'background.paper' }}
          subheader={<ListSubheader sx={{color:state.brandColor1}}>General</ListSubheader>}>
            <ListItem sx={{paddingRight:0}}>
              <ListItemIcon>
                <InstallMobileIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-app-install" primary={"Install App"} secondary={(state.appStatusInstallation === 'installed') ? "App is already installed on this device.":"Installs this app on this device."} />
              <Switch
                edge="end"
                onChange={()=>handleAppInstallSwitch()}
                checked={state.appStatusInstallation === 'installed' ? true : false}
                disabled={state.appStatusInstallation === 'uninstalled' ? false : true}
                inputProps={{
                  'aria-labelledby': 'switch-list-app-install',
                }}
                sx={{justifySelf:"left"}}
              />
            </ListItem>
            <ListItem sx={{paddingRight:0}}>
              <ListItemIcon>
                <NfcIcon />
              </ListItemIcon>
              {state.nfcAvailable === false
                ? <>
                    <ListItemText id="switch-list-nfc-permission" sx={{marginRight:3}} primary={"Grant NFC Permission"} secondary={"NFC not available on this device."}/>
                    <IndeterminateCheckBoxIcon sx={{justifySelf:"left", marginRight:1}}/>
                  </>
                : <>
                    <ListItemText id="switch-list-nfc-permission" sx={{marginRight:3}} primary={"Grant NFC Permission"} secondary={textSecondaryGrantNfc[state.nfcPermission]}/>
                    <NfcCheckBox />
                  </>

              }
            </ListItem>
          </List>
          <Divider />
          <List
            sx={{ width: '100%', maxWidth: 450, bgcolor: 'background.paper' }}
            subheader={<ListSubheader sx={{color:state.brandColor1}}>Scan options</ListSubheader>}>
            <Typography sx={{ ml: 2, flex: 1, color:"#3b3b3b" }} variant="body2" component="div">
              Attention, these settings are for experienced users only!
            </Typography>
            <ListItem sx={{paddingRight:0}}>
              <ListItemIcon>
                <AutoModeOutlinedIcon />
              </ListItemIcon>
              <ListItemText id="switch-list-write-mode" sx={{marginRight:3}} primary="Seamless Write Mode" secondary="All warnings are suppressed before writing to another device." />
              <Checkbox checked={state.settingSeamlessWrite} onChange={()=>{seamlessWriteTogglehandler()}} sx={{justifySelf:"left"}}/>
            </ListItem>
            {state.settingAuthenticated
             ?  <ListItem sx={{paddingRight:0}}>
                  <ListItemIcon>
                    <HealingIcon />
                  </ListItemIcon>
                  <ListItemText id="switch-list-write-mode" sx={{marginRight:3}} primary="Force Write Mode" secondary="Writing activated without any check. Devices can be repaired but they can also be damaged!" />
                  <Checkbox checked={state.settingForceWrite} onChange={()=>{forceWriteTogglehandler()}} sx={{justifySelf:"left"}}/>
                </ListItem>
              : ''
            }
          </List>
        </Box>
      </Dialog>
    </div>
  );
}
