// Firebase
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

// Set the configuration for your firebase app
const firebaseConfig = {
  apiKey: "AIzaSyC2kMWZ-L9tVvfMvfKdddFih333sp1GEy4",
  authDomain: "cpi-kde-0003-01-gen.firebaseapp.com",
  projectId: "cpi-kde-0003-01-gen",
  storageBucket: "cpi-kde-0003-01-gen.appspot.com",
  messagingSenderId: "42618768329",
  appId: "1:42618768329:web:39324683b7f80f4446f618"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig, "nfcApp");

// Get a reference to the storage service, which is used to create references in your storage bucket
export const storage = getStorage(firebaseApp);



