import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../contexts/context';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import AnnouncementIcon from '@mui/icons-material/Announcement';

export default function ErrorDialog() {
  const [ state, dispatch ] = useContext(AppContext);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    dispatch({
      type: 'app/ui/dialog/error',
      title: null,
      text: null
    });
  };

  useEffect(() => {
    if (state.errorText || state.errorTitle) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [state.errorText, state.errorTitle]);

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="form-dialog-error" open={open}>
        <DialogTitle
          id="form-dialog-error"
          onClose={handleClose}
          sx={{display:"flex", flexDirection:"column", margin:0, paddingBottom:0, alignItems:"center", justifyContent:"center"}}
          >
        {state.errorTitle !== null
          ? state.errorTitle
          : 'Error'
        }
        </DialogTitle>
        <DialogContent sx={{display:"flex", flexDirection:"column", paddingY:1, alignItems:"center", justifyContent:"center",}}>
          <Avatar
              variant="square"
              sx={{
                bgcolor: "#b71c1c",
                width: 90,
                height: 90,
                margin: 2,
                }}
            >
            <AnnouncementIcon sx={{ fontSize:70}}/>
          </Avatar>
          <DialogContentText sx={{mt:0}}>
          {state.errorText !== null
              ? state.errorText
              : 'Unknown Error'
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
