class ndefReader extends EventTarget {
  #reader = window.NDEFReader ? new window.NDEFReader() : new EventTarget();
  #scanController = null;
  #writeController = null;
  #ignoreRead = false;

  constructor () {
    super();

    this.#reader.onreading = (event) => {
      // if (this.#ignoreRead) {
      //   console.info(`ndefReader | constructor(): ignoreRead`)
      //   return;
      // }
      console.info(`ndefReader | onreading():`, event)
    }

    this.#reader.onreadingerror = (event) => {
      console.info(`ndefReader | onreadingerror():`, event)
    }
  }

  // ### Privat
  // Write once without previous reading.
  async #write(ndef) {
    console.info(`ndefReader | write(): start`)
    if (!this.#writeController) {
      console.info(`ndefReader | write(): new AbortController`)
      this.#writeController = new AbortController();
    }

    if (this.#reader.write) {
      try {
          console.info('ndefReader | write(): Write ...');
          await this.#reader.write(ndef, {
          overwrite: true,
          signal: this.#writeController.signal
        });
      } catch (error) {
        if (error.name === 'AbortError') {
          console.info('ndefReader | write(): Request aborted');
        } else {
          console.error('ndefReader | write() Error:', error);
        }
        throw error;
      }
    } else {
      console.info('ndefReader | write(): Create new writer!');
      const writer = new this.#reader.NDEFWriter();
      try {
        await writer.write(ndef, {
          overwrite: true,
          signal: this.#writeController.signal
        });
      } catch (error) {
        if (error.name === 'AbortError') {
          console.info('ndefReader | write(): Request aborted');
        } else {
          console.error('ndefReader | write() Error:', error);
        }
      }
    }
    this.#ignoreRead = false;
  }

  // ### Public

  // Function for permanent reading
  read(eventHandler) {
    this.#reader.addEventListener(
      "reading",
      eventHandler
    );
  }

  // Function for read once
  readOnce(eventHandler) {
    this.#reader.addEventListener(
      "reading",
      eventHandler,
      { once: true }
    );
  }

  // Write with ignoreRead
  async write(data) {
    try {
      await this.#write(data);
    } catch (err) {
      console.error(`ndefReader | write():`, err)
      throw err;
    }
  }

  async error(eventHandler) {
    this.#reader.addEventListener(
      "readingerror",
      eventHandler
    );
  }

  // Use eventhandler to check readout & calling the write(data) function
  async checkBeforeWrite(eventHandler) {
    console.info(`ndefReader | checkBeforeWrite(): start`)
    this.#ignoreRead = true;
    this.#reader.addEventListener(
      "reading",
      eventHandler,
      { once: true }
    );
  }

  getIgnoreRead() {
    return this.#ignoreRead;
  }

  writeAbort() {
    if (this.#writeController) {
      console.info(`ndefReader | writeAbort()`)
      this.#writeController.abort();
      this.#writeController = null;
    }
  }

  scan() {
    if (!this.#scanController) {
      console.info(`ndefReader | scan(): new AbortController`)
      this.#scanController = new AbortController();
      this.#reader.scan({ signal: this.#scanController.signal });
    }
  }

  async check() {
    try {
      await this.#reader.scan();
    } catch (err) {
      console.error(`ndefReader | scan():`, err)
      throw err;
    }
  }

  stop() {
    if (this.#scanController) {
      this.#scanController.abort();
      this.#scanController = null;
    }
  }
}

export default ndefReader;
