
import { useContext } from 'react';
import { AppContext } from '../contexts/context';
// Tools
import { updatedDiff } from 'deep-object-diff';

// ## JS Functions
export const getTimeString = () => {
  // Zeit als String in der Form "14:55" erstellen
  const nowTime = new Date();
  const hour = nowTime.getHours().toString().padStart(2, '0');
  const minute = nowTime.getMinutes().toString().padStart(2, '0');
  const seconds = nowTime.getSeconds().toString().padStart(2, '0');
  const timeString = `${hour}:${minute}:${seconds}`;
  return timeString;
}

export const getDateTimeString = () => {
  // Zeit als String in der Form "240723-112517" erstellen
  const nowTime = new Date();
  const year = nowTime.getFullYear().toString().slice(2);
  const month1 = nowTime.getMonth() + 1;
  const month = month1.toString().padStart(2, '0');
  const day = nowTime.getDate().toString().padStart(2, '0');
  const hour = nowTime.getHours().toString().padStart(2, '0');
  const minute = nowTime.getMinutes().toString().padStart(2, '0');
  const seconds = nowTime.getSeconds().toString().padStart(2, '0');
  const timeString = `${year}${month}${day}-${hour}${minute}${seconds}`;
  return timeString;
}

// ## React Hooks
export const useCheckImportedData = () => {
  const [ state ] = useContext(AppContext);
  let result = false;

  if ((!Object.keys(state.originP).length && Object.keys(state.nfcDataP).length)
  || (!Object.keys(state.originPR).length && Object.keys(state.nfcDataPR).length)
  || (!Object.keys(state.originPP).length && Object.keys(state.nfcDataPP).length)
  ) {
    result = true;
  }

  return result;
}

export const useCountDataKeys = () => {
  const [ state ] = useContext(AppContext);

  let result = 0;
  var updatedP = {};
  var updatedPR = {};
  var updatedPP = {};

  if (useCheckImportedData()) {
    if (Object.keys(state.nfcDataP).length) {
      result += Object.keys(state.nfcDataP.P).length;
    }
    if (Object.keys(state.nfcDataPR).length) {
      result += Object.keys(state.nfcDataPR.PR).length;
    }
    if (Object.keys(state.nfcDataPP).length) {
      result += Object.keys(state.nfcDataPP.PP).length;
    }
  } else {
    updatedP = updatedDiff(state.originP, state.nfcDataP)
    updatedPR = updatedDiff(state.originPR, state.nfcDataPR)
    updatedPP = updatedDiff(state.originPP, state.nfcDataPP)

    if (Object.keys(updatedP).length) {
      result += Object.keys(updatedP.P).length;
    }
    if (Object.keys(updatedPR).length) {
      result += Object.keys(updatedPR.PR).length;
    }
    if (Object.keys(updatedPP).length) {
      result += Object.keys(updatedPP.PP).length;
    }
  }

  if (result) {
    console.debug("useCountDataKeys: result", result)
    console.debug("useCountDataKeys: updatedP", updatedP)
    console.debug("useCountDataKeys: updatedPR", updatedPR)
    console.debug("useCountDataKeys: updatedPP", updatedPP)
  }
  return result;
}

export const useCountImportedValues = () => {
  const [ state ] = useContext(AppContext);
  let result = 0;

  for (let key in state.nfcDataP.P) {
      if (state.nfcDataP.P[key] !== "") {
          result++;
      }
  }
  for (let key in state.nfcDataPR.PR) {
      if (state.nfcDataPR.PR[key] !== "") {
          result++;
      }
  }
  for (let key in state.nfcDataPP.PP) {
      if (state.nfcDataPP.PP[key] !== "") {
          result++;
      }
  }

  return result;
}

export const useGetLogo = () => {
  const [ state ] = useContext(AppContext);
  return state.appBrandLogoUrl;
}

export const useTimeString = () => {

  return getTimeString();
}

export const removeQueryParams = () => {
  const currentUrl = window.location.href;
  const baseUrl = currentUrl.split('?')[0]; // Trennt die URL in Basis und Query-Parameter
  window.history.replaceState({}, '', baseUrl); // Ersetzt die URL im Verlauf
}
