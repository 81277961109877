exports.recordId = {
  Uri: 0,
  S: 1,
  CP: 2,
  P: 3,
  PP: 4,
  PR: 5,
  V: 6,
  VP: 7
}
