import React, { useEffect, useRef, useContext } from 'react';

// Reducer
import { AppContext } from '../contexts/context';

// containers
import { recordId } from './RecordData'
import { idbGetData } from "./indexedDB"

// Components
import { removeQueryParams } from './../components/AppUtils';

// Firebase
import { storage } from "./Firebase"
import { ref, getDownloadURL } from "firebase/storage";

// Tools
import LZString from 'lz-string';

const DataImport = () => {
  // global context
  const [ state, dispatch ] = useContext(AppContext);
  const myURL = new URL(window.location.href);
  var searchParams = new URLSearchParams(myURL.search);
  var queryCheck = useRef(false);
  var importSchemaState = useRef('pending');
  // Firebase storage reference
  const storageRef = ref(storage);

  useEffect(() => {
    if (myURL.search.length >= 5) {
      if (searchParams.has('D')) {
        queryCheck.current = true;
        if (searchParams.has('N')) {
          state.uiImportConfigName = searchParams.get('N')
        }
        console.debug("DataImport | useEffect: Data found!")
      } else {
        queryCheck.current = false;
      }
    }
  });

  function convertArraysToObjects(input) {
    const output = {};

    for (const key in input) {
      if (Array.isArray(input[key])) {
        output[key] = {};
        input[key].forEach((item, index) => {
          if (item !== '') {
            output[key][index.toString().padStart(3, '0')] = item;
          }
        });
      } else {
        output[key] = input[key];
      }
    }

    return output;
  }

  async function fetchSchemaData(url) {
    console.debug("DataImport | fetchSchemaData: started ... ");
    try {
      const response = await fetch(url);
      console.debug("DataImport | fetchSchemaData: await fetch ... ");
      try {
        const result = await response.json();
        console.debug("DataImport | fetchSchemaData: Schema downloaded ... ");
        console.debug("DataImport | fetchSchemaData: Schema:", result);
        console.debug("DataImport | fetchSchemaData: Schema as JSON:", JSON.stringify(result));
        dispatch({type: 'data/S/updated', value: result});
        importSchemaState.current = 'done';
      } catch (error) {
        console.error('DataImport | fetchSchemaData: response failed:', error);
      }
    } catch (error) {
      console.error('DataImport | fetchSchemaData: fetch failed:', error);
    }
  }

  function importFromIdb (obj, key) {
    var result = false;
    idbGetData(obj[key]).then(data => {
      if (data) {
        console.log(`DataImport | IndexedDB: Schema with ID ${obj[key]} from IndexedDB:`, data);
        dispatch({ type: 'data/S/updated', value: data });
        importSchemaState.current = 'done';
        result = true;
      } else {
        console.error("DataImport | IndexedDB: load error:", obj[key])
      }
    }).catch((res) => {
      console.debug("DataImport | IndexedDB: idb error", res)
    });
    return result;
  }

  function processDataImport(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key in recordId) {
          if (key === 'S') {
            importSchemaState.current = 'pending';

            var idbResult = importFromIdb(obj, key);
            console.debug(`DataImport | idbResult:`, idbResult);

            if (idbResult === false && navigator.onLine) {
              console.debug(`DataImport | processDataImport: Download started for ${obj[key]} ... `);
              try {
                getDownloadURL(ref(storageRef, `${obj[key]}/schema.json`))
                .then((url) => {
                  console.debug("DataImport | processDataImport: URL", url)
                  try {
                    fetchSchemaData(url);
                  } catch (error) {
                    console.log("DataImport | processDataImport: error", error);
                    if (importSchemaState.current !== 'done') {
                      importSchemaState.current = 'failed';
                    }
                  }
                })
                .catch((data) => {
                  dispatch({
                    type: 'app/ui/snackbar/show',
                    message: `Failed to retrieve data ${obj[key]}`,
                    severity: 'warning'
                  });
                  console.error("processDataImport | Download data: ", data);
                  if (importSchemaState.current !== 'done') {
                    importSchemaState.current = 'failed';
                  }
                });
              } catch (error) {
                console.error('processDataImport | Error getDownloadURL', error);
                if (importSchemaState.current !== 'done') {
                  importSchemaState.current = 'failed';
                }
              }
            } else {
              dispatch({
                type: 'app/ui/dialog/error',
                title: `Error importing data`,
                text: `The data is out of date and needs to be updated. Establish an online connection and try again.`
              });
            }
          } else {
            let subObj = {[key]: obj[key]};
            console.debug(`DataImport subObj`, subObj);

            dispatch({type: `data/${key}/imported`, value: subObj});
            dispatch({type: `ndef/control/updateScanTime`});
            if (!(Object.keys(obj[key]).length === 0 && obj[key].constructor === Object)) {
              console.debug(`DataImport Dispatch data/${key}/updated with value`, obj[key]);
            } else {
              console.warn(`DataImport ${key} is empty!`);
            }
          }
        }
      }
    }
  }

  const importDataByKeys = () => {
    let result = true;

    console.debug('DataImport | importDataByKeys: Try to restore data from URL:', searchParams)
    var compressedDataString = searchParams.get('D')
    if (compressedDataString === null) {
      console.error('Error: compressedDataString:', compressedDataString)
      return false;
    }
    console.debug('DataImport | importDataByKeys: compressedDataString', compressedDataString)
    var dataImportString = LZString.decompressFromEncodedURIComponent(compressedDataString);

    if (dataImportString !== null) {
      console.debug('DataImport | importDataByKeys: dataString', dataImportString)
      var dataImportObject = JSON.parse(dataImportString);
      if (dataImportObject !== null) {
        console.debug("DataImport | importDataByKeys: dataImportObject", dataImportObject);
        var dataimportRestoredObject = convertArraysToObjects(dataImportObject)

        if (dataimportRestoredObject !== null) {
          console.debug("DataImport | importDataByKeys: dataimportRestoredObject", dataimportRestoredObject);
          processDataImport(dataimportRestoredObject);
        } else {
          console.error('DataImport | importDataByKeys: Convert Array failed!')
          result = false;
        }
      } else {
        console.error('DataImport | importDataByKeys: Parsing data failed!')
        result = false;
      }
    } else {
      console.error('DataImport | importDataByKeys: Decompress failed!')
      result = false;
    }
    return result;
  }

  useEffect(() => {
    if (queryCheck.current) {
      console.debug(`DataImport: UseEffect`);
      if (!importDataByKeys()) {
        dispatch({
          type: 'app/ui/dialog/error',
          title: `Error importing data`,
          text: `An error has occurred while processing the data. Try again or ask for a new link.`
        });
      }
      removeQueryParams();
      queryCheck.current = false;
    }
    console.debug("DataImport | switch:", importSchemaState.current)
    switch (importSchemaState.current) {
      case 'failed':
        dispatch({
          type: 'app/ui/dialog/error',
          title: `Download Schema Error`,
          text: `File could not be found.`
        });
        break;
      case 'done':
        dispatch({type: 'app/ui/dialog/default/show', value: 'import'});
        break;
      case 'pending':
      default:
        // do nothing
        break;
    }
  }, [ queryCheck.current, importSchemaState.current]);

  return (
    <div>
    </div>
  );

}

export default DataImport;
