import React, { useContext } from "react";
import {Typography} from "@mui/material";
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import InfoIcon from '@mui/icons-material/Info';
import FeedbackIcon from '@mui/icons-material/Feedback';

import start_img from "../assets/info_illustration.png"
import progress_img from "../assets/progress_illustration.png"
import success_img from "../assets/success_illustration.png"
import error_img from "../assets/error_illustration.png"

import { AppContext } from '../contexts/context';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';


export const ImageCard = ()=>{
    const theme = useTheme()

    const [ state ] = useContext(AppContext);
    function getImagePath(type){
        switch (type) {
            case "start":
                return start_img
            case "progress":
                return progress_img
            case "success":
                return success_img
            case "error":
                return error_img
            default:
                return start_img
        }
    }

    return(
        <Card raised={false}
              sx={{
                border:'1px solid',
                borderColor: theme.palette.primary.dark,
                width: "98%",
                paddingY: 1,
                flex: 7,
                bgcolor: '#fff',
                borderRadius: 5,
                marginBottom: 1,
                display: "flex",
                flexDirection: "column",
                minHeight: "50%",
                alignItems: "center",
                justifyContent: "space-between",
                boxShadow: "none"
              }}>
          <CardHeader
              title={state.scanDialog_ImageCardHeadline}
              sx={{
                marginTop: 0,
                marginBottom: 1,
                paddingY: 1,
              }}
          />
          <CardMedia
              component="img"
              image={getImagePath(state.scanDialog_ImageCardType)}
              alt="Status image"
              sx={{
                alignSelf: "center",
                borderRadius: 5,
                minHeight: "50%",
                objectFit: "contain"
              }}
          />
          <CardContent>
              <Typography variant="body2" color="text.secondary" algin="center">
                { state.scanDialog_ImageCardSubtext}
              </Typography>
          </CardContent>
        </Card>
    )
}

const CardIcon = () => {
  const [ state ] = useContext(AppContext);
  const theme = useTheme()
  switch (state.scanDialog_alertType) {
    case "info":
      return(
        <Avatar sx={{ bgcolor: "#fff", padding:0.9 }} aria-label="status_icon">
          <InfoIcon color={"primary"} sx={{fontSize:50, color: theme.palette.primary.dark}}/>
        </Avatar>
      )
    case "success":
      return(
        <Avatar sx={{ bgcolor: theme.palette.primary.dark, padding:0.9 }} aria-label="status_icon">
          <AutoModeIcon sx={{fontSize:41, color:"#fff"}}/>
        </Avatar>
      )
    case "warning":
      return(
        <Avatar sx={{ bgcolor: "#fff", padding:0.9 }} aria-label="status_icon">
          <FeedbackIcon color={"primary"} sx={{fontSize:50, color: '#ff9800'}}/>
        </Avatar>
      )
    case "error":
      return(
        <Avatar sx={{ bgcolor: "#b71c1c", padding:0.5,}} aria-label="status_icon">
          <SyncProblemIcon sx={{fontSize:41, color:"#fff"}}/>
        </Avatar>
      )
    case "processing":
      return(
        <Avatar sx={{ bgcolor: theme.palette.primary.dark, padding:0.9 }} aria-label="status_icon">
          <CircularProgress sx={{fontSize:41, color:"#fff"}} />
        </Avatar>
      )
    default:
      break;
  }
}

// Outlined icons with filled avatar
// Headline font size

export const AlertCard = ()=>{
  const [ state ] = useContext(AppContext);
  const theme = useTheme()

  const statusColors = {
    info: theme.palette.primary.dark,
    success: theme.palette.primary.dark,
    warning: '#ff9800',
    error: '#d50000',
    processing: theme.palette.primary.dark
  }

  return(
    <Card sx={{
        border: '1px solid',
        borderColor: statusColors[state.scanDialog_alertType],
        width: "90%",
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
        paddingX: 2,
        boxShadow: "none",
        alignItems: "left",
        justifyContent: "left"
      }}>
      <CardHeader
          avatar={
            CardIcon()
          }
          title={state.scanDialog_alertHeadline}
          subheader={state.scanDialog_alertSubHeadline}
          titleTypographyProps={{marginLeft:0, color:"text.secondary", variant:"h6", fontWeight: 'normal'}}
      />
      <CardContent sx={{mt:0, paddingY:0}}>
        <Typography variant="body2" color="text.secondary">
          {state.scanDialog_alertText}
        </Typography>
      </CardContent>
    </Card>
  )
}
