import React, { useState, useContext, useEffect } from "react";
import { Box } from '@mui/material';
import Draggable from "react-draggable";
import EditNoteIcon from '@mui/icons-material/EditNote';
import Typography from '@mui/material/Typography'
import ReadIcon from '@mui/icons-material/ManageSearch';


import { useTheme } from '@mui/material/styles';
import {Dimensions} from 'react-native';
import { AppContext } from '../contexts/context';
import { useCountDataKeys } from "./AppUtils";

export const ScanSlider=(props)=>{
  const theme = useTheme();
  const [ state ] = useContext(AppContext);
  const changedParams = useCountDataKeys()

  let [mode, setMode] = useState(props.checked)

  useEffect(() => {
    if(changedParams>0 || state.nfcDataScanTime!=="unknown"){
      //Enable Write
      setMode(true)
      props.onChange(true)
    }
  }, []);


  const parentWidth=()=>{
    let snap = ((Dimensions.get('window').width)*(1))-0.4
    return(snap/2)
  }

  const toggleSlider=(e, data)=>{
    if (data.lastX > parentWidth()/2) {
      setMode(true)
      props.onChange(true)

    } else {
      setMode(false)
      props.onChange(false)

    }
  }

  const onClickHandler=(e)=>{
    if (props.disabled  || props.writeDisabled) {
      // empty
    } else {
      props.onChange(e)
      setMode(e)
    }
  }

  const sliderPosX=(mode)=>{
    if (props.writeDisabled) {
      return 0
    } else {
      if (mode===false) {
        return 0
      } else if (mode===true) {
        //return (parentWidth()*0.75)
        return ((parentWidth()*0.97)-40)
      }
    }
  }

  return(
    <Box sx={{width:"100%", height:80, shadowOffset:{width:0, height:1}, shadowRadius:3, shadowOpacity: 1, shadowColor: 'rgba(0, 0, 0, 0.7)'}} display={"flex"} justifyContent={"center"} alignItems={"center"} >
      <Box width={"100%"} height={"80%"} backgroundColor={theme.palette.primary.light} borderRadius={40} position={'relative'} paddingX={.4} display={"flex"} alignItems={"center"}>
        <Box onClick={()=>{onClickHandler(false)}} borderRadius={5} width={"100%"} height={"80%"} display={"flex"} justifyContent={'center'} alignItems={'center'}>
          <ReadIcon fontSize='medium' sx={{marginRight:0.5, color:props.disabled?"#919191" :theme.palette.primary.main}}/>
          <Typography sx={{color:props.disabled?"#919191" :theme.palette.primary.main, fontSize:14}}>Read Mode</Typography>
        </Box>
        <Box onClick={()=>{onClickHandler(true)}} borderRadius={5} width={"100%"} height={"80%"} display={"flex"} justifyContent={'center'} alignItems={'center'}>
          <EditNoteIcon fontSize='medium' sx={{ marginRight:0.5, color:props.disabled?"#919191" :theme.palette.primary.main}}/>
          <Typography sx={{color:props.disabled?"#919191" :theme.palette.primary.main, fontSize:14}}>Write Mode</Typography>
        </Box>
        <Draggable onStop={(e, data) => {toggleSlider(e, data)}} disabled={props.disabled} axis={'x'} bounds={"parent"} grid={[parentWidth(), parentWidth()]} position={{x:sliderPosX(mode), y:0}}>
          <Box position={"absolute"} display={"flex"} flexDirection={"row"} width={props.writeDisabled?"80%":"50%"} height={"95%"} alignItems={"center"} justifyContent={"center"} backgroundColor={props.disabled?"#919191" : theme.palette.primary.main}
            borderRadius={37} paddingX={0.5} paddingY={0.1}>
            <Box borderRadius={5} width={"100%"} height={"80%"} display={"flex"} justifyContent={'center'} alignItems={'center'}>
              {mode===false?<ReadIcon fontSize={"medium"} sx={{color:"white", marginRight:1}}/>:<EditNoteIcon fontSize='medium' sx={{ marginRight:1, color:"white"}}/>}
              <Typography sx={{color:"white", fontSize:16}}>{mode===false?"Read Mode":"Write Mode"}</Typography>
            </Box>
          </Box>
        </Draggable>
      </Box>
    </Box>
  )
}
