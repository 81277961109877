import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { ContextProvider } from './contexts/context'

const swCallback={};

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <App swCallback={swCallback}/>
    </ContextProvider>
  </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    onSuccess: () => {
      console.debug('serviceWorkerRegistration | onSuccess')
      if (swCallback.onSuccess) {
        console.debug('serviceWorkerRegistration | onSuccess - swCallback')
        swCallback.onSuccess(); // delegating the swCallback
      }
    },
    onUpdate: (reg) => {
      console.debug('serviceWorkerRegistration | onUpdate')
      if (swCallback.onUpdate(reg)) {
        console.debug('serviceWorkerRegistration | onUpdate - swCallback')
        swCallback.onUpdate(); // delegating the swCallback
      }
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


