// indexedDB.js

const dbName = "nfc-config-db";
const dbVersion = 1;
const dbStore = 'schema';

function idbCreate() {
  const request = indexedDB.open(dbName, dbVersion);
  // console.debug(`IndexedDB | idbCreate started`, request);

  // Only called if db is newer
  request.onupgradeneeded = event => {
    console.debug(`IndexedDB | idbCreate: onupgradeneeded`);
    const db = event.target.result;
    if (!db.objectStoreNames.contains(dbStore)) {
      console.debug(`IndexedDB | idbCreate: Object Store creation`, dbStore);
      db.createObjectStore(dbStore, { keyPath: 'dbKey' });
    } else {
      console.warn(`IndexedDB | idbCreate: Object Store already exist:`, dbStore);
    }
  };
}

function idbCheck() {
  const request = indexedDB.open(dbName, dbVersion);
  request.onsuccess = event => {
    const db = event.target.result;
    console.debug(`IndexedDB | idbCheck: `, db);
    console.debug(`IndexedDB | idbCheck: `, db.objectStoreNames.length);
    if(db.objectStoreNames.length === 0) {
      console.error(`IndexedDB | idbCheck: db emtpy!`);
    }
  };
}

function idbAddData(dbKey, dbData) {
  const request = indexedDB.open(dbName, dbVersion);
  console.debug(`IndexedDB | idbAddData started`, request);

  request.onsuccess = event => {
    const db = event.target.result;
    const transaction = db.transaction(dbStore, 'readwrite');
    const store = transaction.objectStore(dbStore);

    console.debug(`IndexedDB | idbAddData: running.`);
    store.put({ dbKey, dbData });

    transaction.oncomplete = () => {
      console.debug(`IndexedDB | idbAddData: Data in ${dbStore} with Key ${dbKey} saved.`);
    };
  };

  request.onerror = event => {
    console.error("IndexedDB | idbAddData: error: ", event);
  };
}

async function idbGetData(schemaId) {
  console.debug("DataImport | idbGetData: schemaId", schemaId)
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, dbVersion);

    request.onsuccess = event => {
      const db = event.target.result;
      console.debug(`DataImport | idbGetData: db`, db)
      console.debug(`DataImport | idbGetData: db.objectStoreNames.length`, db.objectStoreNames.length)
      if (db.objectStoreNames.length === 0) {
        console.debug(`DataImport | idbGetData: db not found`)
        reject('db not found');
      } else {
        const transaction = db.transaction(dbStore);
        const objectStore  = transaction.objectStore(dbStore);
        console.debug(`DataImport | idbGetData: store`, objectStore)
        const request = objectStore.get(schemaId);
        console.debug(`DataImport | idbGetData: request`, request)

        request.onsuccess = (event) => {
          resolve(request.result ? request.result.dbData : null);
        };

        request.onerror = (event) => {
          reject('DataImport | idbGetData: Error fetching data from IndexedDB');
        };
      }
    };

    request.onerror = event => {
      reject('Error opening IndexedDB');
    };
  });
}

export {
  idbCreate,
  idbCheck,
  idbAddData,
  idbGetData
};
